"use client";
import styles from "./banner.module.scss";
import { AstroImages, AstroLinks, QuickLinksData } from "../constants";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import useAppContext from "@/customhooks/useAppContext";
import { RUPEE_ORANGE_ICON } from "@/utils/imageRelativePaths";
export default function Banner() {
  const router = useRouter();
  const context = useAppContext();
  const { auth, setPopups } = context;
  const token = auth?.token;
  const quickLinksRef = useRef<HTMLDivElement>(null);
  const BannerImages = [AstroImages.BANNER_IMG];
  const [slide, setSlide] = useState(0);
  const [x, setX] = useState(150);
  const [y, setY] = useState(150);
  const handleNavigation = (next?: boolean) => {
    const isNext = next ?? false;
    if (quickLinksRef?.current) {
      quickLinksRef.current.scrollTo({
        left: quickLinksRef.current.scrollLeft + 200 * (isNext ? 1 : -1),
        behavior: "smooth",
      });
    }
  };

  const openPage = (data: { link: string; title: string }) => {
    if (data?.title === "Wallet" && (!token || token.length == 0)) {
      setPopups({ showLoginPopup: true });
      return;
    }
    if (data && data.link) {
      router.push(data.link);
    }
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <div className={styles["banner_main_cont"]}>
      <div className={styles.astroLinksContainer}>
        {AstroLinks.map(({ label, list, externalLink }) => {
          return (
            <div
              className={styles.astroLink}
              onMouseEnter={(e) => {
                document.body.style.overflow = "hidden";
                setX(e.currentTarget.getBoundingClientRect()?.x);
                setY(e.currentTarget.getBoundingClientRect()?.y);
              }}
              onMouseLeave={(e) => (document.body.style.overflow = "visible")}
              onClick={() => {
                externalLink ? window.open(externalLink, "_blank") : "";
              }}
            >
              {label}
              {label === "Book a Pooja" && (
                <div className={styles.newTag}>New</div>
              )}
              <div
                className={styles.sublinkContainer}
                style={{ left: x, top: y }}
              >
                {list?.map(({ label, link }) => (
                  <div
                    onClick={() => {
                      externalLink
                        ? window.open(externalLink, "_blank")
                        : router.push(link);
                    }}
                    className={styles.subLink}
                  >
                    {label}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles["banner_cont"]}>
        <Image
          className={styles["planet_img"]}
          src={AstroImages.PLANET_IMG}
          alt={"Planet"}
        />
        <div className={styles["banner_text"]}>
          <h1>Are you worried about your future?</h1>
          <h2>
            Where celestial guidance meets digital convenience. Explore your
            destiny,connect with authentic
            <span> Astrologers Live</span>
          </h2>
          <div
            className={styles["banner_btn"]}
            onClick={() => router.push("/chat")}
          >
            <div className={styles["icon_cont"]}>
              <Image src={AstroImages.MESSAGE_ICON} alt="" />
            </div>
            Chat With <span className={styles["astrologer"]}>Astrologer</span>{" "}
            <span className={styles["rate"]}>
              (
              <Image
                src={RUPEE_ORANGE_ICON}
                alt={"rupee"}
                height={14}
                width={14}
              />{" "}
              10/min)
            </span>
          </div>
          <div
            className={styles["banner_btn"]}
            onClick={() => router.push("/call")}
          >
            <div className={styles["icon_cont"]}>
              <Image src={AstroImages.PHONE_ICON} alt="" />
            </div>
            Talk to <span className={styles["astrologer"]}>Astrologer</span>{" "}
            <span className={styles["rate"]}>
              (
              <Image
                src={RUPEE_ORANGE_ICON}
                alt={"rupee"}
                height={14}
                width={14}
              />{" "}
              15/min)
            </span>
          </div>
        </div>
        <div className={styles["banner_slider"]}>
          {[1].map((item, index) => (
            <div
              className={styles["slider_img_cont"]}
              // style={{ transform: `translateX(-${slide * 100}%)` }}
              key={index}
            >
              <Image
                priority={true}
                className={styles["slider_img"]}
                src={BannerImages[index]}
                alt=""
              />
              {/*<Image*/}
              {/*  className={styles["play_btn"]}*/}
              {/*  src={AstroImages.PLAY}*/}
              {/*  alt=""*/}
              {/*/>*/}
            </div>
          ))}
          {/*<div className={styles["slider_img_cont_dots"]}>*/}
          {/*  {[1, 2, 3].map((item, index) => (*/}
          {/*    <span*/}
          {/*      key={index}*/}
          {/*      className={index == slide ? styles["selected"] : ""}*/}
          {/*    ></span>*/}
          {/*  ))}*/}
          {/*</div>*/}
        </div>
      </div>
      <div className={styles["quick_links_cont"]} ref={quickLinksRef}>
        {/*<Image*/}
        {/*  src={AstroImages.PREV_ICON}*/}
        {/*  alt={"Prev"}*/}
        {/*  className={styles["prev"]}*/}
        {/*  onClick={() => handleNavigation()}*/}
        {/*/>*/}
        {QuickLinksData.filter((item) => item.link).map((item, index) => (
          <div
            className={`${styles["quick_link"]} ${
              item?.link ? "" : styles["quick_link_overlay"]
            }`}
            key={index}
            onClick={() =>
              openPage({ link: item?.link ?? "", title: item?.title })
            }
          >
            <div
              className={styles["gradient_back"]}
              style={{ background: item.gradient }}
            ></div>
            <Image
              className={styles["quick_link_icon"]}
              src={item.icon}
              alt={item.title}
            />
            <Image
              className={styles["quick_link_img"]}
              src={item.background}
              alt={item.title}
            />
            <p>{item.title}</p>
          </div>
        ))}
        {/*<Image*/}
        {/*  src={AstroImages.PREV_ICON}*/}
        {/*  alt={"Next"}*/}
        {/*  className={styles["next"]}*/}
        {/*  onClick={() => handleNavigation(true)}*/}
        {/*/>*/}
      </div>
    </div>
  );
}
